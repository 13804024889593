import React from 'react'

export default class Email extends React.Component {
  constructor() {
    super()
    this.state = { email: '' }
  }

  componentDidMount() {
    this.setState({
      email:
        '<a href="mailto:info@waerder-lorenz.de">info@waerder-lorenz.de</a>',
    })
  }

  render() {
    return <span dangerouslySetInnerHTML={{ __html: this.state.email }} />
  }
}
