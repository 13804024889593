import React from 'react'
import { Link } from 'gatsby'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'

export default class SiteNavbar extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    return (
      <Navbar
        className="navbar-expand-lg navbar-dark bg-light p-0 d-lg-none"
        id="navbar"
      >
        <NavbarBrand className="mx-auto" href="#">
          Waerder & Lorenz Steuerberater
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle}>
          <span className="navbar-toggler-icon" />
        </NavbarToggler>
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav navbar className="mr-auto">
            <NavItem>
              <Link
                className="nav-link text-white"
                activeClassName="active"
                to="/"
              >
                Startseite
              </Link>
            </NavItem>
            <NavItem>
              <Link
                className="nav-link text-white"
                activeClassName="active"
                to="/ueberuns"
              >
                Über uns
              </Link>
            </NavItem>
            <NavItem>
              <Link
                className="nav-link text-white"
                activeClassName="active"
                to="/dienstleistungen"
              >
                Dienstleistungen
              </Link>
            </NavItem>
            <NavItem>
              <Link
                className="nav-link text-white"
                activeClassName="active"
                to="/kontakt"
              >
                Kontakt
              </Link>
            </NavItem>
            <NavItem>
              <Link
                className="nav-link text-white"
                activeClassName="active"
                to="/impressum"
              >
                Impressum
              </Link>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    )
  }
}
