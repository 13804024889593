import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'
import villa from '../images/villa2.png'
import SiteNavbar from './SiteNavbar'
import Email from './Email_address'
import Helmet from 'react-helmet'
import CookieConsent from 'react-cookie-consent'

export default ({ children }) => (
  <Container>
    <Row>
      <CookieConsent
        location="top"
        buttonText="OK"
        style={{ backgroundColor: '#DE0424' }}
        buttonStyle={{ background: 'white', borderRadius: '3px' }}
      >
        Willkommen! Diese Webseite und darin eingebundene externe Dienste
        verwenden Cookies. Weitere Informationen zum Datenschutz, externen
        Diensten und Ihren Rechten finden Sie in unserer{' '}
        <u>
          <Link className="text-reset" to="/impressum#datenschutz">
            Datenschutzerklärung
          </Link>
        </u>
        .
      </CookieConsent>
    </Row>
    <Helmet>
      <title>Waerder & Lorenz Steuerberater</title>
      <html lang="de" />
      <meta
        name="keywords"
        content="Einkommensteuer, Einkommensteuererklärung, Umsatzsteuer, Gewerbesteuer, Erbschaftsteuer, Schenkungsteuer, Körperschaftsteuer, Abgeltungsteuer, Buchhaltung, Gehaltsabrechnung, Lohnabrechnung, Umsatzsteuervoranmeldung, Zinsabschlagsteuer, Vorsteuer, Bilanz, Jahresabschluss, Einkommensteuergesetz, Umsatzsteuergesetz, Gewerbesteuergesetz, Körperschaftsteuergesetz, Steuerberatung, Existenzgründung, Sozialversicherung, Finanzbuchhaltung, Lohnbuchhaltung, Betiebswirtschaftliche Auswertung, Einkünfte, Erlöse, Betriebsausgaben, Betriebseinnahmen, Gewinnermittlung, Lohnsteuer, Lorenz, Burscheid, Steuerprüfung, Waerder, Finanzamt, Finanzen"
      />
      <meta
        name="description"
        content="Dienstleistung bedeutet f&uuml;r uns N&auml;he zum Mandanten und pers&ouml;nliche Erreichbarkeit in allen fachlichen Fragen. Unser Team begleitet Sie mit viel Berufserfahrung von der Existenzgr&uuml;ndung bis zur Nachfolgeberatung und unterst&uuml;tzt Sie als Unternehmer und Steuerpflichtigem mit: Betriebswirtschaftlicher Beratung, Aufbau des Rechnungswesens, wahlweise als Service f&uuml;r Sie in unserer Kanzlei oder in Ihrem Unternehmen; Jahresabschluss mit Erl&auml;uterung der einzelnen Bilanzpositionen; Lohn- und Gehaltsabrechnung, Deklaration aller betrieblichen und pers&ouml;nlichen Steuern unabh&auml;ngig von der Gesellschaftsform "
      />
      <meta name="title" content="Waerder-Lorenz Steuerberater in Burscheid" />
      <meta name="author" content="Sascha Boch Softwareentwicklung" />
      <meta name="publisher" content="Waerder & Lorenz Steuerberater" />
      <meta name="copyright" content="Waerder & Lorenz Steuerberater" />
      <meta name="revisit-after" content="after 20 days" />
      <meta name="page-topic" content="Steuerberater" />
      <meta name="page-type" content="Gesch&auml;ftlich" />
      <meta name="robots" content="index,follow" />
      <meta name="generator" content="http://www.waerder-lorenz.de" />

      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />

      <meta http-equiv="Content-Style-Type" content="text/css" />
    </Helmet>

    <Row className="d-none d-lg-block">
      <Col className="text-center">
        <img
          className="header-image"
          src={villa}
          alt="Waerder & Lorenz Steuerberater Partnerschaftsgesellschaft mbB"
        />
      </Col>
    </Row>
    <Row className="text-center navbar-lg text-white font-weight-bold ml-0 mr-0">
      <Link
        to="/"
        className="col text-white topnavitem"
        activeClassName="active"
      >
        <Col className="d-none d-lg-block topnavitem">Startseite</Col>
      </Link>
      <Link
        to="/ueberuns"
        className="col text-white topnavitem"
        activeClassName="active"
      >
        <Col className="d-none d-lg-block topnavitem">Über uns</Col>
      </Link>
      <Link
        to="/dienstleistungen"
        className="col text-white topnavitem"
        activeClassName="active"
      >
        <Col className="d-none d-lg-block topnavitem">Dienstleistungen</Col>
      </Link>
      <Link
        to="/kontakt"
        className="col text-white topnavitem"
        activeClassName="active"
      >
        <Col className="d-none d-lg-block topnavitem">Kontakt</Col>
      </Link>
      <Link
        to="/impressum"
        className="col text-white topnavitem"
        activeClassName="active"
      >
        <Col className="d-none d-lg-block topnavitem">Impressum</Col>
      </Link>
    </Row>
    <SiteNavbar />
    {children}
    <footer className="text-muted text-center small my-2">
      &copy; {new Date().getFullYear()} Waerder & Lorenz Steuerberater &middot;
      Kölner Str. 56 &middot; 51399 Burscheid &middot; Telefon: (02174) 78392-0
      &middot; Fax: (02174) 78392-29 &middot; <Email />
    </footer>
  </Container>
)
