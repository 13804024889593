import React from 'react'
import Layout from '../components/layout'
import { Row, Col, Card, CardBody, CardImg, CardTitle } from 'reactstrap'
import waerder from '../images/waerder-bild2.jpg'
import lorenz from '../images/lorenz.jpg'

export default () => (
  <Layout>
    <Row>
      <Col className="d-flex align-items-stretch">
        <Card>
          <CardImg top src={waerder} alt="Porträt Hans Peter Waerder" />
          <CardBody>
            <CardTitle>
              <h4>Hans Peter Waerder</h4>
            </CardTitle>
            <h5>Dipl. Finanzwirt</h5>
            <div className="card-text">
              <ul className="pl-4">
                <li>
                  Absolvent der Finanzfachhochschule in Nordkirchen als Diplom
                  Finanzwirt 1968
                </li>
                <li>
                  Tätigkeit als Betriebsprüfer beim Finanzamt Remscheid bis 1976
                </li>
                <li>Prüfung zum Steuerbevollmächtigten 1977</li>
                <li>Aufnahme als Partner in die Steuerkanzlei Paetzold 1978</li>
                <li>Prüfung zum Steuerberater 1984</li>
                <li>Aufnahme Tätigkeit in Einzelkanzlei 1986</li>
              </ul>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col className="d-flex align-items-stretch">
        <Card>
          <CardImg top src={lorenz} alt="" />
          <CardBody>
            <CardTitle>
              <h4>Jochen Lorenz</h4>
            </CardTitle>
            <h5>Dipl. Ökonom</h5>
            <div className="card-text">
              <ul className="pl-4">
                <li>
                  Absolvent der Bergischen Universität Wuppertal als Diplom
                  Ökonom 1999
                </li>
                <li>Prüfung zum Steuerberater 2002</li>
                <li>Übernahme Einzelkanzlei Siegfried Lorenz 2002</li>
              </ul>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Layout>
)
